import React from 'react'
import Helmet from 'react-helmet'
import PageHeader from 'components/PageHeader'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { Paragraph } from 'components/Typography'
import styles from './index.module.less'
import Layout from 'components/Layout'
import image1 from 'images/project-18b.png'
import image2 from 'images/project-18c.png'
import image3 from 'images/project-18d.png'

const Page = () => (
  <Layout>
    <Helmet
      title="Much-loved home brought &lsquo;back to life&rsquo;"
      meta={[
        {
          name: 'description',
          content: 'The Trust was approached to help a Porirua family with a home in need of desperate repair.',
        },
      ]}
    >
      <html lang="en" />
    </Helmet>
    <PageHeader
      title="Much-loved home brought &lsquo;back to life&rsquo;"
      intro="The Trust was approached to help a Porirua family with a home in need of desperate repair."
    />
    <Grid fluid>
      <Row>
        <Col sm={8} className={styles.projectText}>
          <Paragraph>
            The Trust was approached to help a Porirua family with a home in need of desperate repair. Due to the owner&rsquo;s ill-health, critical house maintenance needs hadn&rsquo;t been met and multiple issues included: a leaking roof, disintegrating exterior weatherboards and windows, a rotten deck, worn kitchen cabinetry and unsafe appliances, flooring needing replacement, and interior walls damaged and peeling. Luckily, the existing roofing iron was in reasonable order, so roofing could be rescrewed and repainted by the family. The exterior weatherboards and windows were also repaired and repainted, along with new hinges and hardware for the windows. Even the outside deck was replaced. Thank you Dulux.
          </Paragraph>
          <Paragraph>
            Every room in the house was redecorated. New carpet and vinyl was laid and interior walls received a replastering and painting &lsquo;spruce up&rsquo;. The kitchen saw new cabinetry installed and benchtops fitted to sit alongside the new modern oven, hob and extraction fan. The bathroom and laundry spaces were refurbished to include a much-improved hot water cylinder.
          </Paragraph>
          <Paragraph>
            It was a positive collaborative effort and pleasing for those involved to see the family embracing the painting work after initially feeling not confident enough to do a good job. Extra members of whānau chipped in to help get the job done where needed.
          </Paragraph>
          <blockquote className={styles.quote}>
            &ldquo;On behalf of our family, we would like to say thank you so much to the Ryhaven Trust with Bernard and the team doing the hard work, bringing the house back to life. It&rsquo;s been good for our family to work together (to transform) the house into such an attractive home.&rdquo;
            <br /><br />
            Homeowner
          </blockquote>
          <img className={styles.image} src={image1} alt="" />
        </Col>
        <Col sm={4} className={styles.projectImages}>
          <img className={styles.image} src={image2} alt="" />
          <img className={styles.image} src={image3} alt="" />
        </Col>
      </Row>
    </Grid>
  </Layout>
)

export default Page
